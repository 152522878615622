/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components";
import theme from '../theme';
import GlobalStyle from '../globalStyle';
import { Helmet } from "react-helmet";
import Header from "./header"
import "./layout.css"
import Footer from "./footer";
import MobileHeader from "./MobileHeader";
import logoImage from "../images/MainLogo.png"
import ReactGA from 'react-ga';

const Layout = ({ children }) => {
  ReactGA.initialize('UA-100770515-15')
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="stylesheet" type="text/css" charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap" rel="stylesheet" />
        <meta name="google-site-verification" content="2x6iiRHt4i4QNirkS-6_GliPu-KyrWPo6xWrqSeQcq8" />
        {/* <meta property="og:description" content="Testing upReach help disadvantaged students from across the UK to realise their potential. Our team of Programme Coordinators provide a highly personalised programme of support to each undergraduate student. Through successful partnerships with 45 top employers and universities, we offer Associates access to a comprehensive range of opportunities and activities to broaden their horizons, understand career pathways and develop the skills, networks and experiences needed for career success. We collaborate closely with other charities in our sector to maximise our joint impact. From working with 39 Associates in 2013, this year have supported over 1,800, while maintaining our personalised approach and expanding the support offered. In October 2019, upReach won the Charity of the Year Award (income < £1m) in the prestigious Charity Times Awards" /> */}
        <meta property="og:image" content={logoImage} />
      </Helmet>
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <MobileHeader />
      <div>
        <main style={{minHeight: "calc(100vh - 294px)"}}>{children}</main>
      </div>
      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
